<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h4 class="mt-5">About me</h4>
      <hr>
      <p>My name is Maximilien Andile. I'm 31.</p>
      <b-img rounded="circle" thumbnail width="200px" alt="Maximilien Andile" :src="require('@/assets/images/me.png')"></b-img>
      <p>I live in Paris (France)</p>
      <p>Coding is my passion. I am a self-taught programmer. I began writing code when I was 12.</p>
      <p>I am currently available to work!</p>
      <p>You can contact me on <b-link href="https://www.linkedin.com/in/maximilienandile/" target="_blank">Linkedin</b-link> or via <b-link href="https://twitter.com/MaximilienAld" target="_blank">Twitter</b-link>.</p>
    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
const title = "Maximilien Andile - Practical Go Lessons Book"
const description = "Presentation of the author of the book Practical Go Lessons Book"
export default {
  name: "TheAuthor",
  metaInfo: {
    title: title,
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: description },
      { name: 'robots', content: "index, follow" },
      { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
      { property: 'og:type', content: "website" },
      { property: 'og:title', content: title},
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:site_name', content: 'Practical Go Lessons' },
      { property: 'twitter:card', content: "summary_large_image" },
      { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
    ],
    link : [
      { rel : "canonical", href : window.location.href}
    ]

  },
  components: {Footer, NavBar},
  created() {
    window.scrollTo(0,0);
  }
}
</script>

<style scoped>

</style>